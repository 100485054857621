.pie-chart, .column-chart {
  height: 100%;

  &__pic {
    width: 100%;
    height: 400px;
    margin: 1em 0;
  }

  &__legend {
    height: 150px;
  }

  g[aria-labelledby*='title'][filter] {
    display:none;
  }
}

.column-chart {
  min-width: 800px;
}