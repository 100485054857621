.socials {
  display: flex;
  align-items: center;

  a {
    font-size: 16px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, .1);
    transition: .2s;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &:hover {
      background-color: rgba(255,255,255, .3);
    }
  }
}