.fancybox-content {
  padding: 48px;
  min-width: 320px;
  width: 600px;
  max-width: 100%;
  background-color: #f2f2f2;
  border-radius: 16px;

  @media screen and (max-width: $mobile-l) {
    min-width: unset;
  }

  &.hug-contents {
    min-width: unset;
    width: unset;
  }
}

.modal-form {
  width: 500px;
  max-width: 100%;
}