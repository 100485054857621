.card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: $dark-shadow;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: .2s;

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }
  }

  a:not(.card__link) {
    position: relative;
    z-index: 2;
    transition: .2s;

    &:hover {
      opacity: .75;
    }
  }

  &__image {
    margin-bottom: 16px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .mark {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &.square {
      position: relative;
      padding-bottom: 100%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }

    &.horizontal {
      padding-bottom: 196/310*100%;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include object-fit(cover);
      }
    }

    img {
      width: 100%;
    }
  }

  .headline-5, .headline-4 {
    margin-bottom: 8px;
  }

  &__desc {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    &>* {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    .body-2 {
      margin-bottom: 0;
      color: $text-gray-700;
    }
  }

  .btn.transparent {
    padding: 0;
  }
}