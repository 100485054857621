.privilege-1 {
  display: flex;
  align-items: flex-start;

  &__img {
    width: 72px;
    height: 72px;
    padding: 24px;
    border-radius: 50%;
    background-color: $text-gray-100;
    margin-right: 40px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      @include object-fit(contain);
    }

    @media screen and (max-width: 480px) {
      width: 52px;
      height: 52px;
      padding: 16px;
      margin-right: 20px;
    }
  }

  [class^="headline"] {
    margin-bottom: 8px;
  }
}