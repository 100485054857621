.disabled-article {
  min-height: 644px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;

  .article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    background-color: #fff;
    padding: 64px 32px;
    position: relative;
    z-index: 1;
    width: 100%;

    &:before {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      content: '';
      height: 160px;
      width: 100%;
      display: block;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.0086472) 6.67%, rgba(255, 255, 255, 0.03551) 13.33%, rgba(255, 255, 255, 0.0816599) 20%, rgba(255, 255, 255, 0.147411) 26.67%, rgba(255, 255, 255, 0.231775) 33.33%, rgba(255, 255, 255, 0.331884) 40%, rgba(255, 255, 255, 0.442691) 46.67%, rgba(255, 255, 255, 0.557309) 53.33%, rgba(255, 255, 255, 0.668116) 60%, rgba(255, 255, 255, 0.768225) 66.67%, rgba(255, 255, 255, 0.852589) 73.33%, rgba(255, 255, 255, 0.91834) 80%, rgba(255, 255, 255, 0.96449) 86.67%, rgba(255, 255, 255, 0.991353) 93.33%, #FFFFFF 100%);
    }

    a {
      color: $brand-primary;
      transition: .2s;

      &:hover {
        color: $brand-primary-hover;
      }
    }
  }
}