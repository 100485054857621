.pagination {
  flex-wrap: wrap;
  display: inline-flex;
  list-style: none;
  padding-left: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: center;

  i {
    display: inline-block;
    font-weight: normal;
    vertical-align: middle;
    font-size: 20px;
  }

  &>li {
    &:not(:last-child) {
      margin-right: 8px;
      margin-bottom: 8px;
    }

    &>a, &>span {
      font-size: 14px;
      font-weight: 500;
      transition: .3s;
      padding: 0 0.5em;
      text-align: center;
      min-width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      color: inherit;
      background: #fff;
      border-radius: 2px;
      border: 1px solid $text-gray-100;

      &:hover,
      &:focus {
        background: $brand-green-100;
      }
    }

    &.active {
      &>a, &>span{
        background: $brand-green;
        border-color: $brand-green;
        color: #fff;
        pointer-events: none;
      }
    }

    &.disabled  {
      a, span {
        pointer-events: none;
        color: $text-gray-300 !important;
      }
    }
  }
}