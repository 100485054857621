.progress-bar {
  border-radius: 5/16+em;
  height: 10/16+em;
  width: 100%;
  background: #dedede;
  margin-bottom: 20/16+em;
  overflow: hidden;

  span {
    background: green;
    display: block;
    height: 100%;
    width: 0;
  }
}