.btn {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 20px;
  background-color: $brand-primary;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  transition: .2s;
  border: none;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    background-color: $brand-primary-hover;
  }

  i {
    font-size: 18px;

    &:last-child {
      margin-left: 8px;
    }

    &:first-child {
      margin-right: 8px;
    }
  }

  &.green {
    background-color: $brand-green;

    &:hover {
      background-color: $brand-green-hover;
    }
  }

  &.light {
    background-color: $brand-quaternary;
    color: $brand-primary;

    &:hover {
      color: $brand-primary-hover;
      background-color: $brand-quaternary-hover;
    }
  }

  &.transparent {
    background: none;
    color: $brand-primary;

    &:hover {
      color: $brand-primary-hover;
      //background-color: $brand-quaternary-hover;
    }

    &.hug {
      padding: 0;
    }
  }

  &.sm {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;

    i {
      font-size: 14px;

      &:last-child {
        margin-left: 6px;
      }

      &:first-child {
        margin-right: 6px;
      }
    }
  }

  &.lg {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px;

    i {
      font-size: 24px;

      &:last-child {
        margin-left: 10px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &.outline {
    background: none;
    color: $brand-primary;
    border: 1px solid;
    padding: 9px 19px;

    &:hover {
      color: $brand-primary-hover;
      background-color: $brand-quaternary;
    }

    &.sm {
      padding: 5px 11px;
    }

    &.lg {
      padding: 11px 31px;
    }
  }

  &.disabled, &[disabled] {
    filter: grayscale(1);
    pointer-events: none;
  }
}

.link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  transition: .2s;
  color: $brand-primary;

  &:hover {
    color: $brand-primary-hover;
  }

  &.sm {
    font-size: 12px;
    line-height: 16px;
  }

  &.lg {
    font-size: 16px;
    line-height: 24px;
  }
}