//Screens
$laptop-l: 1440px;
$laptop-m: 1200px;
$laptop-s: 1024px;
$tablet: 767px;
$mobile-l: 480px;
$mobile-m: 375px;
$mobile-s: 320px;

//Colors
$brand-primary: #983D3F;
$brand-primary-hover: #c74649;
$brand-secondary: #62403E;
$brand-tertiary: #EF434A;
$brand-quaternary: #FEF1F1;
$brand-quaternary-hover: #ffdfdf;
$brand-green: #3D9998;
$brand-green-hover: #22adaa;
$brand-green-100: #DBF8F7;

$text-gray-100: #F4F5F7;
$text-gray-200: #EEEFF4;
$text-gray-300: #D9DBE1;
$text-gray-700: #969BAB;
$text-gray-800: #474A57;
$text-gray-900: #18191F;

$blue: #3C64B1;
$red: #EB5757;
$green: #219653;

$padding: 24px;

$dark-shadow: 0 10px 20px rgba(41, 41, 42, 0.07);