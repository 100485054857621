.footer {
  background-color: $brand-secondary;
  color: #fff;
  padding: 80px 0 20px;

  .logo {
    width: 240px;
    margin-bottom: 24px;
  }

  .copyright {
    margin-top: 60px;
  }
}