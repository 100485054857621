.dropdown, .hover-dropdown {
  position: relative;

  &.show {
    .dropdown__content {
      display: block;
    }
  }

  &__btn {
    transition: .3s;
  }

  &__content {
    position: absolute;
    top: 100%;
    display: none;
    z-index: 9;
  }

  &--right {
    [class*="dropdown__content"] {
      right: 0;
    }
  }

  &--center {
    [class*="dropdown__content"] {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.hover:hover {
    @media screen and (min-width: 1025px) {
      [class*="dropdown__content"] {
        display: block;
      }
    }
  }
}