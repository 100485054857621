.breadcrumbs {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: $text-gray-900;

    &:not(:last-child) {
      &:after {
        content: '/';
        margin: 0 5px;
        color: $text-gray-700;
      }
    }

    a {
      transition: .2s;
      color: $text-gray-700;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}