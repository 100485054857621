.about {
  @media screen and (min-width: 481px) {
    .row.row--multiline {
      margin: -24px;

      [class^=col] {
        padding: 24px;
      }
    }
  }

  .headline-2 {
    margin-bottom: 32px;
  }

  .lead-1 {
    margin-bottom: 32px;
  }
}