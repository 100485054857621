.header {
  box-shadow: 0 1px 0 #E5E9F2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;

  .container {
    display: flex;
    align-items: center;
    height: 76px;

    @media screen and (max-width: 1024px) {
      position: relative;
    }

    @media screen and (max-width: 480px) {
      height: 64px;
    }
  }

  .logo {
    margin-right: 32px;

    img {
      display: block;
      width: 132px;

      @media screen and (max-width: 480px) {
        width: 100px;
      }
    }
  }

  .mobile-menu {
    flex-grow: 1;
    margin-right: 32px;

    @media screen and (max-width: 1024px) {
      display: none;
      position: fixed;
      top: 76px;
      left: 0;
      right: 0;
      bottom: 0;
      height: calc(100% - 76px);
      background-color: #fff;
      margin-right: 0;
      box-shadow: $dark-shadow;
      border-top: 1px solid $text-gray-100;
    }

    @media screen and (max-width: 480px) {
      top: 64px;
      height: calc(100% - 64px);
    }

    &__inner {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1024px) {
        padding: 20px;
        flex-direction: column;
        overflow-y: auto;
        height: 100%;
      }
    }
  }

  .menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
    margin-right: auto;

    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    & > li {
      margin-right: 32px;

      @media screen and (max-width: 1024px) {
        flex-grow: 1;
        flex-basis: 0;
      }

      @media screen and (max-width: 768px) {
        border-bottom: 1px solid $text-gray-100;
        margin-bottom: 12px;
        padding-bottom: 12px;
      }

      a {
        padding: 10px 0;
        display: block;
        margin-bottom: 0;
        transition: .2s;

        &:not(.active):hover {
          opacity: .75;
        }

        &.active {
          color: $brand-primary;
          font-weight: 600;
        }
      }
    }

    .dropdown-item {
      position: relative;

      @media screen and (min-width: 1025px) {
        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
      }

      & > a {
        display: flex;
        align-items: center;

        @media screen and (min-width: 1025px) {
          &:after {
            content: "\e914";
            font-family: icomoon;
            font-size: 18px;
            margin-left: 4px;
          }
        }
      }

      .dropdown-menu {
        @media screen and (min-width: 1025px) {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
        }

        &.dropdown-content {
          @media screen and (max-width: 1024px) {
            padding: 0;
            box-shadow: none;
            min-width: unset;
            animation: none;
          }
        }
      }
    }
  }

  .profile-dropdown {
    .dropdown__btn {
      padding: 10px 0;
      display: block;

      img {
        width: 40px;

        @media screen and (max-width: 480px) {
          width: 32px;
        }
      }
    }
  }

  .language {

    a {
      transition: .2s;
      margin-bottom: 0;
    }

    .active {
      color: $brand-primary;
      font-weight: 600;
    }

    a:not(.active):hover {
      opacity: .75;
    }
  }

  .notifications {
    font-size: 20px;
    transition: .2s;
    margin-right: 32px;

    &:hover {
      opacity: .75;
    }

    @media screen and (max-width: 1024px) {
      margin-left: auto;
    }

    @media screen and (max-width: 480px) {
      font-size: 18px;
      margin-right: 24px;
    }

    &.icon-bell:before {
      display: none;
    }
  }

  .menu-btn {
    font-size: 20px;
    margin-left: 32px;

    @media screen and (max-width: 480px) {
      font-size: 18px;
      margin-left: 24px;
    }

    &.active:before {
      content: "\e917";
    }
  }
}
