.carousel {
  @media screen and (max-width: 1024px) {
    width: calc(100% - 100px);
    margin: 0 auto;
  }

  .slick-list {
    margin: 0 -15px;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      padding: 0 15px;

      &>div {
        height: 100%;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    background-color: $text-gray-100;
    transition: .2s;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $brand-primary;
    right: calc(100% + 10px);

    &.icon-chevron-right {
      right: unset;
      left: calc(100% + 10px);
    }

    &:hover {
      color: $brand-primary-hover;
      background-color: $text-gray-200;
    }
  }
}