.alert {
  font-size: 14/16+em;
  margin: 15/14+em 0;
  padding: 16.5/12+em;
  color: #828282;
  background: #F5F5F5;
  border-radius: 4px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  *:last-child {
    margin-bottom: 0;
  }

  &-success {
    color: #005e2c;
    background: #C5FDDF;
  }

  &-info {
    color: #347797;
    background: #BEEAFF;
  }

  &-warning {
    color: #977B34;
    background: #FFF9BE;
  }

  &-danger {
    color: #973434;
    background: #FFDBDB;
  }
}
