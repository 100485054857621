.main {
  padding-top: 76px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    padding-top: 64px;
  }

  &>* {
    &:last-child {
      flex-grow: 1;
    }
  }
}