.hero-1 {
  background: url("/assets/img/hero-bg-wide.jpg") center center no-repeat;
  background-size: auto 100%;
  padding: 70px 0;

  @media screen and (max-width: 1024px) {
    background: none;
  }

  .headline-1 {
    margin-bottom: 16px;
  }

  .gray-block {
    max-width: 420px;
    margin: 0 auto;
  }
}