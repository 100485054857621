.partners {
  .item {
    img {
      width: 140px;
      height: 100px;
      @include object-fit(contain);
      display: block;
      margin: 0 auto;
    }
  }
}