.text-uppercase {
  text-transform: uppercase;
}

hr {
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid $text-gray-300;
}

.gray-100 {
  color: $text-gray-100;
}
.gray-200 {
  color: $text-gray-200;
}
.gray-300 {
  color: $text-gray-300;
}
.gray-700 {
  color: $text-gray-700;
}
.gray-800 {
  color: $text-gray-800;
}

.color-primary {
  color: $brand-primary;
}

.color-secondary {
  color: $brand-secondary;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-64 {
  margin-bottom: 64px;
}

.max-540 {
  width: 540px;
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}