.form-group {
  &:not(:last-child) {
    margin-bottom: 15px;
  }

  [class^="label"] {
    margin-bottom: 8px;
  }
}

.input, select {
  font-family: inherit;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 15px;
  background-color: $text-gray-100;
  border-radius: 2px;
  transition: .2s;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
    background-color: $text-gray-200;
  }

  &.outline {
    background-color: #fff;
    border: 1px solid $text-gray-300;
    padding: 9px 14px;

    &:focus {
      background-color: $text-gray-100;
    }

    &.sm {
      padding: 5px 8px;
    }

    &.lg {
      padding: 11px 17px;
    }
  }

  &.sm {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 9px;
  }

  &.lg {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 18px;
  }
}

textarea.input-regular {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100% !important;
  display: block;
  min-height: 100px;
}

form {
  .row.row--multiline {
    margin: -7.5px;

    [class^=col] {
      padding: 7.5px;
    }
  }
}

.checkbox, .radio {
  cursor: pointer;

  input {
    display: none;

    &:checked+span:after {
      opacity: 1;
    }
  }

  input+span {
    padding-left: 32px;
    position: relative;

    &:before, &:after {
      content: "\e91c";
      font-family: 'icomoon';
      position: absolute;
      left: 0;
      top: 0;
      font-size: 20px;
      color: $text-gray-300;
      line-height: 1;
    }

    &:after {
      content: "\e918";
      opacity: 0;
      transition: .2s;
      z-index: 1;
      color: $brand-green;
    }

    a {
      color: $brand-primary;
      transition: .2s;

      &:hover {
        color: $brand-primary-hover;
      }
    }
  }
}

.radio {
  input+span {
    &:before {
      content: "\e919";
    }

    &:after {
      content: "\e91a";
    }
  }
}