.categories {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  a {
    transition: .2s;
    color: $text-gray-700;

    &:not(:last-child) {
      margin-right: 24px;
    }


    &:hover {
      color: $text-gray-800;
    }

    &.active {
      font-weight: bold;
      color: $text-gray-900;
    }
  }
}