section {
  padding: 80px 0;

  @media screen and (max-width: 480px) {
    padding: 50px 0;
  }

  &.gray {
    background-color: $text-gray-100;
  }

  &.pink {
    background-color: $brand-quaternary;
  }
}