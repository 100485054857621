.contact {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  i {
    font-size: 20px;
    margin-right: 8px;
  }
}