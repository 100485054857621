.article {

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .article-image {
    float: left;
    width: 50%;
    margin-right: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    img {
      width: 100%;
    }
  }

  .article-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &>* {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .article-body {
    display: table;
  }

  .plain-text {
    text-align: justify;
  }
}