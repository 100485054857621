.dropdown-content {
  padding: 12/16+em 22/16+em;
  animation: fadeDown .3s forwards;
  box-shadow: $dark-shadow;
  background-color: #fff;
  min-width: 160/16+em;


  @keyframes fadeDown {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}