.gray-block {
  background-color: $text-gray-100;
  padding: 24px;
  border-radius: 8px;

  @media screen and (min-width: 481px) {
    &.p-48 {
      padding: 48px;
    }

    &.p-32 {
      padding: 32px;
    }
  }
}