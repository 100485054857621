.selectize-control {
  $font-size: 14;
  $arrow-size: 20;
  $arrow-padding: 15;
  $input-height: 40;
  $bg: #fff;
  $side-padding: 15;
  $border-width: 1;
  $arrow-down: "\e914";
  $arrow-up: "\e901";
  $active-border-color: $brand-primary;
  $active-bg-color: #fff;
  $border-color: $text-gray-300;
  $arrow-color: #828282;
  $item-bg: $text-gray-200;
  $active-item-bg: $text-gray-200;
  $remove-color: $red;
  $loading-color: $brand-primary;
  $dropdown-margin: 5;
  $item-color: inherit;
  $elements-gray: $text-gray-300;

  //Calculated vars
  $border-radius: 2;

  font-size: $font-size+px;

  .selectize-dropdown, .selectize-input, .selectize-input input {
    font-size: 1em;
    line-height: unset;
  }

  &.single {
    .selectize-input {
      background: $bg;
      box-shadow: none;
      height: $input-height+px;
      display: flex !important;
      align-items: center;
      border: 1px solid $border-color;
      border-radius: #{$border-radius}px;
      padding-right: ($arrow-size+$arrow-padding*2)+px;
      padding-left: $side-padding+px;
      //line-height: calc(#{$input-height}em/#{$font-size} - #{2*$border-width}px);

      [data-value] {
        display: block;
      }

      .item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:after {
        content: $arrow-down;
        font-family: 'icomoon';
        color: $arrow-color;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        margin-top: 0 !important;
        border: none;
        width: auto;
        height: 100%;
        font-weight: 400;
        font-size: $arrow-size+px;
        padding: 0 $arrow-padding/$arrow-size+em;
      }

      &.dropdown-active:after, &.focus:after {
        content: $arrow-up;
      }

      &.has-items.input-active {
        [data-value] {
          //display: none;
        }
      }
    }
  }

  &.multi {
    .selectize-input {
      border: 1px solid $border-color;
      border-radius: #{$border-radius}px;
      padding-left: $side-padding+px;
      padding-right: ($arrow-size+$arrow-padding*2)+px;
      box-shadow: none;
      background: $bg;
      min-height: $input-height+px;
      display: flex;
      flex-wrap: wrap;

      &.has-items {
        padding: 10+px;

        input {
          padding: 5px 10px !important;
        }

        &:after {
          display: none;
        }
      }

      .item, .item.active {
        background-color: $item-bg;
        color: $item-color;
        text-shadow: none;
        box-shadow: none;
        border: none;
        padding: 6.5px 10px;
        margin: 4px;
        border-radius: 10px;
        overflow: hidden;
      }

      .item.active {
        background: $active-item-bg;
      }

      &:after {
        content: $arrow-down;
        font-family: 'icomoon';
        color: $arrow-color;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        right: 0;
        margin-top: 0 !important;
        border: none;
        width: auto;
        height: 100%;
        font-weight: 400;
        font-size: $arrow-size+px;
        padding: 0 $arrow-padding/$arrow-size+em;
      }

      &.dropdown-active:after, &.focus:after {
        content: $arrow-up;
      }

      &>input {
        width: unset !important;
      }
    }

    &.one-line {
      .selectize-input {
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        padding: 3px 16px;
        overflow-x: auto;
        scrollbar-width: thin;
        scrollbar-color: $elements-gray rgba(0,0,0,0);
        cursor: pointer;

        /* Works on Chrome/Edge/Safari */
        &::-webkit-scrollbar {
          width: 4px;
          height: 8px;
          cursor: pointer;
        }
        &::-webkit-scrollbar-track {
          background: rgba(0,0,0,0);
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $elements-gray;
          border-radius: 4px;
          cursor: pointer;
        }

        .item, .item.active {
          flex-shrink: 0;
          margin: 0 4px 0 0;
        }
      }
    }
  }

  &.white {
    .selectize-input {
      background-color: #fff;
    }
  }

  .selectize-input {

    &:before {
      display: none;
    }

    &.dropdown-active {
      //border-color: $active-border-color;
      background-color: $active-bg-color;
    }

    input {
      cursor: pointer;
    }
  }

  .selectize-dropdown {
    margin-top: $dropdown-margin+px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0,0,0,.1);
    border: 1px solid $border-color;
    border-radius: 2px;
    min-width: 100%;

    .selectize-dropdown-content {
      //padding: 10+px 0;
      max-height: 205px;
    }

    .option {
      padding: 10+px $side-padding+px;
      cursor: pointer;
    }

    &.loading {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(255,255,255,.8);
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        width:50px;
        height:50px;
        border:5px solid $loading-color;
        border-radius:50%;
        border-top:5px solid transparent;
        animation:spin 1.25s linear infinite;
        transition: opacity .3s;
      }

      @keyframes spin{
        0%{
          transform:rotate(0deg);
        }
        100%{
          transform:rotate(360deg);
        }
      }
    }

    .optgroup {
      margin-bottom: 1rem;
    }

    .optgroup-header {
      font-weight: 600;
      padding-left: 1em;
    }
  }

  &.plugin-remove_button [data-value] {
    padding-right: 2.5em !important;

    .remove {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-weight: 400;
      width: 30px;
      border-left: none;
      padding-bottom: 3px;
      color: $remove-color;

      &:hover {}
    }
  }
}

.selectized {
  display: block !important;
  position: absolute;
  width: 0 !important;
  height: 0 !important;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}