.plain-text {

  &.gray {
    color: $text-gray-700;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      position: relative;
      padding-left: 1.5em;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:before {
        content: '•';
        margin-right: .7em;
        margin-left: 2px;
        color: $brand-green;
        border-radius: 50%;
        flex-shrink: 0;
        position: absolute;
        left: 0;
      }
    }

    &[style="list-style-type: circle;"] {
      li {
        &:not(:last-child) {
          margin-bottom: 30px;
        }

        &:before {
          margin-top: 0.6rem;
          width: 8px;
          height: 8px;
          margin-right: calc(1rem - 2px);
          background: $brand-green;
        }
      }
    }
  }

  ol {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    li {
      counter-increment: number;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:before {
        content: counter(number) '. ';
        margin-right: .2em;
        color: $brand-green;
        flex-shrink: 0;
        min-width: 1.5em;
      }
    }
  }

  a {
    color: $brand-green;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &>*:last-child {
    margin-bottom: 0;
  }

  iframe {
    width: 100%;
  }

  img {
    height: auto !important;
    display: block;
    margin: 0 auto 1rem;

    @media screen and (max-width: $tablet) {
      width: 100% !important;
    }
  }

  blockquote {
    margin: 0 0 1rem;
    padding: 20px;
    background: $brand-green-100;
    font-style: italic;

    &>*:last-child {
      margin-bottom: 0;
    }
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 1rem;
  }

  .videoWrapper object,
  .videoWrapper embed,
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  p, ol, ul {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }

  &.blue {
    color: $blue;
  }

  table {
    font-size: 1em;
    width: unset !important;
    min-width: 100%;
    table-layout: fixed;
    word-break: break-word;
    border-collapse: collapse;

    *:last-child {
      margin-bottom: 0;
    }

    th, td {
      padding: .5em;
      border: 1px solid #909090;
    }
  }

  .scroll-x {
    @media screen and (max-width: $tablet) {
      margin: 0 -1.25rem;
    }
    
    &__inner {
      padding: 0;

      @media screen and (max-width: $tablet) {
        padding: 0 1.25rem;
      }
    }
  }
}