.dropdown-list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  li {
    &:not(:last-child) {
      //margin-bottom: 8/16+em;
      //padding-bottom: 8/16+em;
      //border-bottom: 1px solid $green;
    }

    &.active {
      a {
        font-weight: bold;
      }
    }
  }

  a {
    font-size: 14/16+em;
    padding: 8/14+em 0;
    display: inline-block;
    transition: .2s;

    &:hover {
      opacity: .75;
    }
  }
}