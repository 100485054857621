.white-block {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;

  @media screen and (min-width: 481px) {
    &.p-48 {
      padding: 48px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}