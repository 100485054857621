.overflow-x {
  overflow-x: auto;
  margin-left: -24px;
  margin-right: -24px;

  &__inner {
    padding: 0 24px;
    display: table;
    width: 100%;
  }
}