.scroll-up {
  position: fixed;
  font-size: 20px;
  color: #fff;
  background-color: $brand-tertiary;
  right: 32px;
  bottom: 32px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: .2s opacity, .2s visibility;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  @media screen and (max-width: 480px) {
    right: 20px;
    bottom: 20px;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: $brand-primary;
  }
}