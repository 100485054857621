@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Regular.eot');
  src: url('/assets/fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Regular.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Regular.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Regular.svg#Inter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-ExtraLight.eot');
  src: url('/assets/fonts/Inter/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-ExtraLight.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-ExtraLight.svg#Inter-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-ExtraBold.eot');
  src: url('/assets/fonts/Inter/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-ExtraBold.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-ExtraBold.svg#Inter-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Bold.eot');
  src: url('/assets/fonts/Inter/Inter-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Bold.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Bold.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Light.eot');
  src: url('/assets/fonts/Inter/Inter-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Light.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Light.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Light.svg#Inter-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Black.eot');
  src: url('/assets/fonts/Inter/Inter-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Black.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Black.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Black.svg#Inter-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-SemiBold.eot');
  src: url('/assets/fonts/Inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-SemiBold.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-SemiBold.svg#Inter-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Thin.eot');
  src: url('/assets/fonts/Inter/Inter-Thin.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Thin.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Thin.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Thin.svg#Inter-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Medium.eot');
  src: url('/assets/fonts/Inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Inter/Inter-Medium.woff') format('woff'),
  url('/assets/fonts/Inter/Inter-Medium.ttf') format('truetype'),
  url('/assets/fonts/Inter/Inter-Medium.svg#Inter-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?o0ikpl');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?o0ikpl#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?o0ikpl') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?o0ikpl') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?o0ikpl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox-on:before {
  content: "\e918";
}
.icon-radio-off:before {
  content: "\e919";
}
.icon-radio-on:before {
  content: "\e91a";
}
.icon-checkbox-indeterminate:before {
  content: "\e91b";
}
.icon-checkbox-off:before {
  content: "\e91c";
}
.icon-close:before {
  content: "\e917";
}
.icon-menu:before {
  content: "\e916";
}
.icon-chevron-right:before {
  content: "\e900";
}
.icon-chevron-up:before {
  content: "\e901";
}
.icon-download:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-left-arrow:before {
  content: "\e905";
}
.icon-mail:before {
  content: "\e906";
}
.icon-mobile:before {
  content: "\e907";
}
.icon-pin:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-right-arrow:before {
  content: "\e90a";
}
.icon-send:before {
  content: "\e90b";
}
.icon-telephone:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}
.icon-vk:before {
  content: "\e90f";
}
.icon-youtube:before {
  content: "\e910";
}
.icon-bell:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-check-circle:before {
  content: "\e913";
}
.icon-chevron-down:before {
  content: "\e914";
}
.icon-chevron-left:before {
  content: "\e915";
}
